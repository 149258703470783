<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm người lao động'"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickDelete="deleteItems"
      @clickAdd="openAddModal"
      @search="search($event)"
    />
    <vue-good-table
      ref="user-table"
      class="mh-60"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            v-b-tooltip.hover.top="'Xóa'"
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
        <span v-else-if="props.column.field === 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>
      </template>
    </vue-good-table>

    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />

    <div class="p-2">
      <b-button
        variant="outline-primary"
        @click="$router.push({ name: 'org-struct-employment' })"
      >
        Quay lại
      </b-button>
    </div>

    <modal-add-user @addSuccess="getListUser" />
    <confirm-modal
      id="confirm-modal"
      :title="''"
      content="Bạn có chắc chắn muốn xóa người dùng khỏi cơ cấu tổ chức không"
      @accept="acceptDelete"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAddUser from './ModalAddUser.vue'
import store from '../../store'

export default {
  components: {
    VueGoodTable,
    BBadge,
    MyPagination,
    Search,
    ConfirmModal,
    BButton,
    VBTooltip,
    ButtonAllHeader,
    ModalAddUser,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      queryParams: {
        organizationStructureEmploymentId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã người lao động',
          field: 'code',
          sortable: false,
        },
        {
          label: 'Tên người lao động',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
      totalRecord: 0,

      showBtnMultiDelete: false,
      deletedIds: [],
    }
  },
  created() {
    this.queryParams.organizationStructureEmploymentId = this.$route.params.id
    this.getListUser()
  },
  methods: {
    async getListUser() {
      this.$showLoading()
      const { data } = await store.getListOrgUser(this.queryParams)
      this.dataList = data.pageLists
      this.totalRecord = data.totalRecord
      this.$hideLoading()
    },
    search(val) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber: 1,
        key: val,
      }
      this.getListUser()
    },
    async openAddModal() {
      this.$bvModal.show('add-user')
    },
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    deleteItems() {
      this.deletedIds = this.$refs['user-table'].selectedRows.map(item => item.id)
      this.$bvModal.show('confirm-modal')
    },
    deleteItem(id) {
      this.deletedIds = [id]
      this.$bvModal.show('confirm-modal')
    },
    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.getListUser()
    },
    async acceptDelete() {
      const params = {
        ids: this.deletedIds,
      }
      const res = await store.removeUserFromOrg(params)
      if (res.success) {
        this.$root.$bvToast.toast('Xóa người dùng khỏi cơ cấu tổ chức thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getListUser()
      }
    },
  },
}
</script>
