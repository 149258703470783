var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm người lao động',"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.openAddModal,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",staticClass:"mh-60",attrs:{"columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):(props.column.field === 'code')?_c('span',[_c('b-badge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('div',{staticClass:"p-2"},[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push({ name: 'org-struct-employment' })}}},[_vm._v(" Quay lại ")])],1),_c('modal-add-user',{on:{"addSuccess":_vm.getListUser}}),_c('confirm-modal',{attrs:{"id":"confirm-modal","title":'',"content":"Bạn có chắc chắn muốn xóa người dùng khỏi cơ cấu tổ chức không"},on:{"accept":_vm.acceptDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }